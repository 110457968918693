import { userStore } from '@context/userStore';
import Icon from '@elements/Icon';
import { classNames } from '@helpers/classNames';
import { addFavorite, deleteFavorite } from '@providers/favorite';
import WithClaim from 'components/auth/WithClaim';
import { usePlausible } from 'next-plausible';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const FavoriteAnimationState = {
  IDLE: '',
  SET_FAVORITE: 'animate__animated animate__heartBeat',
  UNSET_FAVORITE: 'animate__animated animate__faster animate__fadeOut',
};

const FavoriteButton = ({ isFavorite, documentId, setUnfavorite, isSetUnfavorite }) => {
  const { profileId } = userStore();
  const [favorite, setFavorite] = useState(isFavorite);
  const [effect, setEffect] = useState(FavoriteAnimationState.IDLE);
  const plausibleEvent = usePlausible();

  useEffect(() => {
    setFavorite(isFavorite);
  }, [isFavorite]);

  function handleUnFavorite(documentId) {
    setFavorite(false);
    if (isSetUnfavorite == true) {
      setUnfavorite(documentId);
    }
    setEffect(FavoriteAnimationState.UNSET_FAVORITE);
  }

  function handleAddFavorite() {
    setFavorite(true);
    setEffect(FavoriteAnimationState.SET_FAVORITE);
  }

  const onClickFavorite = async () => {
    if (favorite) {
      handleUnFavorite(documentId);
      const response = await deleteFavorite(profileId, documentId);
      if (response && response.status !== 200) {
        handleAddFavorite();
      }
    } else {
      handleAddFavorite();
      const response = await addFavorite(profileId, documentId);
      if (response && response.status !== 201) {
        handleUnFavorite(documentId);
      }
    }
  };

  return (
    <WithClaim claim="favorite" altComponent={null}>
      <button
        className={classNames(effect, 'h-8 w-8 rounded-full')}
        onClick={() => {
          onClickFavorite();
          plausibleEvent('Favoriet', { props: { documentId, favorite: !favorite } });
        }}
        onAnimationEnd={() => setEffect(FavoriteAnimationState.IDLE)}
        type="button"
      >
        <Icon
          iconName={favorite ? 'heart' : 'heart-outline'}
          color={favorite ? 'text-accent' : 'text-primary-dark'}
        />
      </button>
    </WithClaim>
  );
};

// PropTypes that help dev's to know what props are available to render component
FavoriteButton.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  documentId: PropTypes.string.isRequired,
};

FavoriteButton.defaultProps = {
  isFavorite: false,
  documentId: '',
};

export default FavoriteButton;

import { classNames } from '@helpers/classNames';
import Footer from './Footer';
import Navbar from './Navbar';

export default function Layout(props) {
  const screenSize = props.screenSize !== undefined ? props.screenSize : true;

  return (
    <div
      className={classNames(
        `flex flex-col justify-between ${screenSize ? 'h-screen' : 'min-h-screen'}`,
      )}
    >
      <Navbar />
      <main className="mb-auto grow">{props.children}</main>
      <Footer />
    </div>
  );
}

import * as Sentry from '@sentry/browser';
import { clientApi } from './clientApi';

export async function addFavorite(profileId, documentId) {
  try {
    return await clientApi(
      '/favorite',
      'POST',
      null,
      JSON.stringify({ profile_id: profileId, document_id: documentId }),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function deleteFavorite(profileId, documentId) {
  try {
    return await clientApi(
      `/favorite?profile_id=${profileId}&document_id=${documentId}`,
      'DELETE',
      null,
      JSON.stringify({ profile_id: profileId, document_id: documentId }),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getFavorites(profileId) {
  try {
    return await clientApi(`/favorite/${profileId}`, 'GET', null);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getFavoritesList(profileId) {
  try {
    return await clientApi(`/favorite/list/${profileId}`, 'GET', null);
  } catch (error) {
    Sentry.captureException(error);
  }
}

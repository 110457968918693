export const publisherData = [
  {
    publisher: 'Vereniging Bouwwerk Begroeners',
    text: 'De Vereniging Bouwwerk Begroeners is branchevereniging voor bouwwerkbegroeners, specialisten in dakbegroening, groengevels, multifunctionele daken en binnenbegroening. De Norm begroeide daken VBB-FLL is gepubliceerd op BouwZo. Meer informatie over De Vereniging Bouwwerk Begroeners <a href="https://www.bouwwerkbegroeners.nl" class="text-accent underline">vind je hier</a>',
  },
  {
    publisher: 'Rijksdienst voor Ondernemend Nederland',
    text: 'RVO beantwoordt vragen van ondernemend Nederland over duurzaam, agrarisch, vernieuwend en internationaal ondernemen. RVO biedt voorlichting, advies en financiering. RVO stelt regelingen op, voert ze uit en zorgt voor de naleving van wetten en regels. Meer informatie over RVO <a href="https://www.rvo.nl" class="text-accent underline">vind je hier</a>.',
  },
  {
    publisher: 'InstallQ',
    text: 'Veilige installaties van goede kwaliteit voor heel Nederland: dat is het doel van de onafhankelijke stichting InstallQ. Om dit doel te bereiken, ontwikkelt en beheert de stichting (certificerings)regelingen en verleent InstallQ erkenningen aan vakbekwame installatiebedrijven. Ook accrediteert InstallQ opleidingen en examens in installatietechniek. De regelingen zijn opgenomen op <a href="https://www.bouwzo.nl" class="text-accent underline">BouwZo.nl</a>. Meer informatie over InstallQ <a href="https://installq.nl" class="text-accent underline">vind je hier</a>.',
  },
  {
    publisher: 'ISSO',
    text: 'ISSO ontwikkelt al sinds 1974 kennis voor de professional. Kennis die je helpt in de dagelijkse praktijk. Inmiddels hebben we meer dan 100 kennisproducten. Deze kennisproducten vind je terug op BouwZo.',
  },
  {
    publisher: 'Rijksvastgoedbedrijf',
    text: 'Het Rijksvastgoedbedrijf beheert de gebouwen en gronden van de Rijksoverheid en Defensie. De dienst valt onder het ministerie van Binnenlandse Zaken en Koninkrijksrelaties en is verantwoordelijk voor het beheer en de instandhouding van de grootste en meest diverse vastgoedportefeuille van Nederland, inclusief Caribisch Nederland. Meer informatie over Het Rijksvastgoedbedrijf <a href="https://www.rijksvastgoedbedrijf.nl" class="text-accent underline">vind je hier</a>.',
  },
  {
    publisher: 'EPB Center',
    text: 'EPB-center richt zich op de internationaal geharmoniseerde set van EPB-beoordelingsmethoden en de implementatie daarvan op nationaal en regionaal niveau.  Ze streven ernaar belanghebbenden en geïnteresseerde partijen technische ondersteuning te bieden voor de implementatie en verspreiding van informatie over de set EPB-normen op nationaal en regionaal niveau.  Naast deze ondersteuning willen we ook helpen bij het formuleren van de behoeften om deze set EPB-normen verder te ontwikkelen en te verbeteren. Meer informatie over EPB-center <a href="https://www.epb.center" class="text-accent underline">vind je hier</a>.',
  },
];

import { NextSeo } from 'next-seo';
import PropTypes from 'prop-types';
import StructuredDataSeo from './StructuredDataSeo';

const Seo = (props) => {
  const {
    title,
    description,
    url,
    imageUrl,
    imageAlt,
    type,
    isbn,
    releaseDate,
    publisher,
    price,
    pathname,
  } = props;

  const twitterConfig = {
    handle: '@issonieuws',
    site: '@site',
    cardType: 'summary_large_image',
  };

  let jsonLinkedData = null;

  switch (type) {
    case 'book':
      // Creates JSON-LD object whihch is passed through to the html head
      // Built according to https://schema.org/Book
      jsonLinkedData = {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        mainEntity: {
          '@type': 'Book',
          bookFormat: 'http://schema.org/Paperback',
          datePublished: releaseDate,
          image: imageUrl,
          inLanguage: 'nl-NL',
          isbn: isbn,
          name: title,
          description: description,
          offers: {
            '@type': 'Offer',
            url: pathname,
            availability: 'http://schema.org/InStock',
            price: price,
            priceCurrency: 'EUR',
          },
          publisher: publisher,
        },
      };
      return (
        <>
          {/* Inject JSON-LD script and default NextSeo tags into the html head for books. */}
          <StructuredDataSeo data={jsonLinkedData} />
          <NextSeo
            title={title}
            description={description}
            openGraph={{
              title: title,
              url: url,
              description: description,
              type: type,
              book: {
                releaseDate: releaseDate,
                isbn: isbn,
              },
              images: [
                {
                  url: imageUrl,
                  alt: imageAlt,
                  width: 850,
                  height: 650,
                },
              ],
            }}
            twitter={twitterConfig}
          />
        </>
      );
    default:
      return (
        <NextSeo
          title={title}
          description={description}
          openGraph={{
            title: title,
            url: url,
            description: description,
            type: type,
            images: [
              {
                url: imageUrl,
                alt: imageAlt,
                width: 850,
                height: 650,
              },
            ],
          }}
          twitter={twitterConfig}
        />
      );
  }
};

// PropTypes that help dev's to know what props are available to render component
Seo.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  isbn: PropTypes.string,
  releaseDate: PropTypes.string,
};

Seo.defaultProps = {
  type: '',
  title: '',
  description: '',
  url: '',
  imageUrl: '',
  imageAlt: '',
  isbn: '',
  releaseDate: '',
};

export default Seo;
